import { FormatHelper } from "core-framework/utils";
import moment from "moment";

declare global {
  interface Date {
    toDateFormat(): string;
    toDateTimeFormat(): string;
    toDateTimeApiRequestFormat(): string;
    addDays(days: number): Date;
    toStringFormat (format:string): string;
  }
}

/** datetime tipi date olarak stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateFormat = function () {
  return FormatHelper.toDateFormat(this);
};

/** datetime tipi datetime olarak stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateTimeFormat = function () {
  return FormatHelper.toDateTimeFormat(this);
};

/** datetime tipi datetime olarak api request için stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateTimeApiRequestFormat = function () {
  return FormatHelper.toDateTimeApiRequestFormat(this);
};

/** İlgili tarih değişkenine gün eklemesi yapar. */
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days: number): Date {
  this.setDate(this.getDate() + days);
  return this;
};

/** İlgili tarih değerini formatlar.  DD.MM.YYYY HH:mm:ss*/
// eslint-disable-next-line no-extend-native
Date.prototype.toStringFormat = function (format:string) {
  return moment(this).format(format);
}; 
 
export {}; 