export class StringOps {
  /* Değerin sayı olduğunu kontrol eder*/
  static isNumber(value: any): boolean {
    let numberRegexp = /^[0-9]+$/;
    return numberRegexp.test(value);
  }

  /* Değerin karakter olduğunu kontrol eder*/
  static isLetter(value: any): boolean {
    let lettersRegexp = /^[A-Za-z]+$/;
    return lettersRegexp.test(value);
  }

  /* Değerin null veya empty olduğunu kontrol eder*/
  static isNullOrEmpty(value: string): boolean {
    let result = true;
    if (value && value !== null && value.length > 0) {
      result = false;
    }

    return result;
  }

  /* Türkçe karakterleri temizle ve özel karakterleri temizle */
  static clearTurkishCharacter(value: string): string {
    // 1. Türkçe karakterler ve karşılık gelen İngilizce karakterler tanımlanıyor.
    const turkceKarakterler = "çğıöşüÇĞİÖŞÜ";
    const ingilizceKarakterler = "cgiosuCGIOSU";

    // 2. Türkçe karakterler İngilizce karakterlere dönüştürülüyor.
    let duzeltilmisMetin = value.replace(
      new RegExp("[" + turkceKarakterler + "]", "g"), // Türkçe karakterlerin bulunduğu bir regex ifadesi oluşturuluyor
      function (harf) {
        const index = turkceKarakterler.indexOf(harf); // Karakterin Türkçe karakterler içindeki indeksi bulunuyor
        if (index > -1) {
          return ingilizceKarakterler.charAt(index); // Türkçe karakter, İngilizce karşılığıyla değiştiriliyor
        }
        return harf; // Eğer karakter Türkçe karakterler listesinde yoksa değişiklik yapılmıyor
      }
    );

    // 3. Diğer özel karakterler siliniyor.
    duzeltilmisMetin = duzeltilmisMetin.replace(/[^a-zA-Z0-9]/g, ""); // Kelime karakterleri ve boşluk dışındaki tüm özel karakterler siliniyor

    return duzeltilmisMetin; // Temizlenmiş metin döndürülüyor
  }
}
