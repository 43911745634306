export class FileOps {
  static async DownloadJsonFile(obj: any, fileName :string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const contents = JSON.stringify(obj)
        var blob = new Blob([contents], { type: "application/json" });
        var file = new File([blob], `${fileName}.json`, { type: "application/json" });
        const fileBlob = await file.arrayBuffer();
        const url = window.URL.createObjectURL(new Blob([fileBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name); //or any other extension
        document.body.appendChild(link);
        link.click();
        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  }
}
