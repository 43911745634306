import moment from "moment";

export class FormatHelper {
  /**Datetime formatında ki DD.MM.YYYY HH:mm:ss Olarak döner */
  static toDateTimeFormat(value: any): string {
    if (value)
      return moment(value).add("hours", 3).format("DD.MM.YYYY HH:mm:ss");

    return "";
  } 
  /**Datetime formatında ki DD.MM.YYYY Olarak döner */
  static toDateFormat(value: any): string {
    if (value) return moment(value).add("hours", 3).format("DD.MM.YYYY");

    return "";
  } 
  /**Metini local halini döner */
  static getLocalText(value: any, t:any, prop:string): string {
    if (value) return t(`${prop}.${value}`);

    return "";
  } 
  /**Datetime formatında ki YYYY-MM-ddTHH:mm:ss Olarak döner 
   * Api get isteklerinde datetime gönderilmek istendiğinde proxy sınıfını burayı kullanır
  */
  static toDateTimeApiRequestFormat(value: Date): string {
    if (value)
      return moment(value).add("hours", 3).format("YYYY.MM.DDTHH:mm:ss");

    return "";
  } 

  static toBoolFormatYesNo(value: any): string {
    if (value) return "Evet";
    else return "Hayır";
  }

  static toEnumText(enm: any, value: number): any {
    return Object.values(enm)[value];
  }

  static byteToMB(value: number): any {
    return (value / 1048576).round(2);
  }

  static toMoneyAmountFormat(value: any): string {
    return value.toLocaleString("tr-TR", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
}
