import Axios, { AxiosError, AxiosResponse } from "axios";
import {
  Authenticate,
  Dictionary,
  IFSPRequest,
  IScreenContext,
  ServiceCallTypeEnum,
} from "core-framework";
import {
  dAuthorizationResponse,
  dTokenReq,
} from "core-framework/auth/authServiceModels";
import { LoggerHelper } from "core-framework/utils";
import { ServiceRouter } from "./serviceRouter";

/* oturum ile api servis çağrısı yapar */
export class ServiceProxy {
  /* oturum bilgisi ile post yapar */
  static async Post<T = any>(
    context: IScreenContext,
    path: string,
    errorCallback: (error: AxiosError) => void,
    request?: any
  ): Promise<T> {
    try {
      const serviceUrl = ServiceRouter.getServiceUrl(path);
      const result = await this.call<T, AxiosResponse<T>>(
        ServiceCallTypeEnum.Post,
        serviceUrl,
        true,
        0,
        context,
        errorCallback,
        request
      );

      return result.data!;
    } catch (err: any) {
      errorCallback(err); //refresh token süresi geçtiğinde başlangıç sayfasına göndermesi için
      throw err;
    }
  }

  /* oturum bilgisi ile post yapar */
  static async Put<T = any>(
    context: IScreenContext,
    path: string,
    guid: string,
    errorCallback: (error: AxiosError) => void,
    request?: any
  ): Promise<T> {
    try {
      let serviceUrl = ServiceRouter.getServiceUrl(path);

      if (guid.length === 0) {
        //eğer bu olmaz ise getbyguide gitmiyor
        throw new TypeError("required guid");
      }

      serviceUrl += `/${guid}`;

      const result = await this.call<T, AxiosResponse<T>>(
        ServiceCallTypeEnum.Put,
        serviceUrl,
        true,
        0,
        context,
        errorCallback,
        request
      );

      return result.data!;
    } catch (err: any) {
      throw err;
    }
  }

  /* oturum bilgisi ile get{guid} yapar */
  static async Delete<T = any>(
    context: IScreenContext,
    path: string,
    guid: string,
    errorCallback: (error: AxiosError) => void,
    params?: Dictionary<string, object>
  ): Promise<T> {
    try {
      let serviceUrl = ServiceRouter.getServiceUrl(path);

      if (guid.length === 0) {
        //eğer bu olmaz ise getbyguide gitmiyor
        throw new TypeError("required guid");
      }

      if (params) {
        let par = "";
        params.values.map((x, i) => {
          if (x.Key !== "guid") {
            if (i === 0) {
              par += `?${x.Key}=${x.Value}`;
            } else {
              par += `&${x.Key}=${x.Value}`;
            }
          }
          return true;
        });
        serviceUrl += `/${guid}` + par;
      } else {
        serviceUrl += `/${guid}`;
      }
      const result = await this.call<T, AxiosResponse<T>>(
        ServiceCallTypeEnum.Delete,
        serviceUrl,
        true,
        0,
        context,
        errorCallback,
        undefined
      );

      return result.data!;
    } catch (err: any) {
      throw err;
    }
  }

  /* oturum bilgisi ile get yapar */
  static async Get<T = any>(
    context: IScreenContext,
    path: string,
    errorCallback: (error: AxiosError) => void,
    params?: Dictionary<string, object>,
    fspRequest?: IFSPRequest
  ): Promise<T> {
    try {
      let serviceUrl = ServiceRouter.getServiceUrl(path);
      if (params) {
        let par = "";
        params.values.map((x, i) => {
          if (i === 0) {
            par += `?${x.Key}=${x.Value}`;
          } else {
            par += `&${x.Key}=${x.Value}`;
          }
          return true;
        });
        serviceUrl += par;
      }

      if (fspRequest) {
        if (serviceUrl.indexOf('?') === -1)
          serviceUrl += "?";
        else
          serviceUrl += "&";

        serviceUrl += `filters=${fspRequest.filters}&`;
        serviceUrl += `sorts=${fspRequest.sorts}&`;
        serviceUrl += `page=${fspRequest.page}&`;
        serviceUrl += `pageSize=${fspRequest.pageSize}`;
      }

      const result = await this.call<T, AxiosResponse<T>>(
        ServiceCallTypeEnum.Get,
        serviceUrl,
        true,
        0,
        context,
        errorCallback,
        undefined
      );

      return result.data!;
    } catch (err: any) {
      throw err;
    }
  }

  /* oturum bilgisi ile get{guid} yapar */
  static async GetByGuid<T = any>(
    context: IScreenContext,
    path: string,
    guid: string,
    errorCallback: (error: AxiosError) => void,
    params?: Dictionary<string, object>
  ): Promise<T> {
    try {
      let serviceUrl = ServiceRouter.getServiceUrl(path);

      if (guid.length === 0) {
        //eğer bu olmaz ise getbyguide gitmiyor
        throw new TypeError("required guid");
      }

      if (params) {
        let par = "";
        params.values.map((x, i) => {
          if (x.Key !== "guid") {
            if (i === 0) {
              par += `?${x.Key}=${x.Value}`;
            } else {
              par += `&${x.Key}=${x.Value}`;
            }
          }
          return true;
        });
        serviceUrl += `/${guid}${par}`;
      } else {
        serviceUrl += `/${guid}`;
      }

      const result = await this.call<T, AxiosResponse<T>>(
        ServiceCallTypeEnum.Get,
        serviceUrl,
        true,
        0,
        context,
        errorCallback,
        undefined
      );

      return result.data!;
    } catch (err: any) {
      throw err;
    }
  }

  //Authenticate Proxy
  static async AuthenticatePost<T = any>(
    request: dTokenReq
  ): Promise<dAuthorizationResponse> {
    const serviceUrl = ServiceRouter.getAuthenticateUrl();

    const result = await ServiceProxy.call<T, AxiosResponse<T>>(
      ServiceCallTypeEnum.Post,
      serviceUrl,
      false,
      1,
      undefined,
      undefined,
      request
    );

    return result.data!;
  }
  static async AuthenticateDelete<T = any>(
    userGuid: string
  ): Promise<dAuthorizationResponse> {
    let serviceUrl = ServiceRouter.getAuthenticateUrl();
    serviceUrl += `/${userGuid}`;

    const result = await ServiceProxy.call<T, AxiosResponse<T>>(
      ServiceCallTypeEnum.Delete,
      serviceUrl,
      true,
      1,
      undefined,
      undefined,
      undefined
    );

    return result.data!;
  }

  static async DownloadFile<T = any>(
    operationName: string,
    context: IScreenContext,
    errorCallback: (error: AxiosError) => void,
    fileGuid: string
  ): Promise<AxiosResponse<T>> {
    let serviceUrl = ServiceRouter.getServiceUrl(operationName);
    serviceUrl += `/${fileGuid}`;

    const result = await ServiceProxy.call<T, AxiosResponse<T>>(
      ServiceCallTypeEnum.Get,
      serviceUrl,
      true,
      0,
      context,
      errorCallback,
      undefined,
      "blob"
    );

    return result!;
  }

  /* api servis çağrını yapar. dışardan authenticate kulanır*/
  static async call<T = any, R = AxiosResponse<T>>(
    method: ServiceCallTypeEnum,
    serviceUrl: string,
    withAuth: boolean,
    count: number,
    context: IScreenContext | undefined,
    errorCallback?: (error: AxiosError) => void,
    request?: any,
    responseType: string = "json"
  ): Promise<R> {
    try {
      const axiosApiInstance = Axios.create();
      axiosApiInstance.defaults.timeout = 1000 * 60; //1000:1sn
      let requestConfig = undefined;

      if (context) {
        requestConfig = ServiceRouter.getRequestApiHeader(
          withAuth,
          context.screenName!,
          responseType
        );
      } else {
        requestConfig = ServiceRouter.getRequestApiHeader(
          withAuth,
          "",
          responseType
        );
      }

      // Axios.interceptors.request.use(
      //   function (config) {
      //     // Do something before request is sent
      //     console.log("request",request);
      //     return config;
      //   },
      //   function (error) {
      //     // Do something with request error
      //     console.log("request err",error);
      //     return Promise.reject(error);
      //   }
      // );

      // Add a response interceptor
      axiosApiInstance.interceptors.response.use(
        function (response) {
          // Do something with response data
          handleDates(response.data); //apide datetime string tipinde geliyor, date çevirmek için
          LoggerHelper.Log("serviceProxy response:", response);
          return response;
        },
        async function (error: AxiosError) {
          //console.log("Error", error);

          //sanırım 200 dışı herşey buraya düşüyor
          // Do something with response error

          if (withAuth === true && error.response!) {
            //login işlemlerin dışında ki istekler düşer
            const statusCode = error.response!.status;
            if (count === 0 && statusCode === 401) {
              //1 kez daha denenmesi için
              //Unauthorized
              //Oturum ile sorgulandıysa refresh token alınıyor
              LoggerHelper.LogInfo(
                "ServiceProxy",
                "Refreshtoken ile token alınıyor"
              );
              try {
                await Authenticate.AuthenticateRefreshToken();
                ServiceProxy.call<T, AxiosResponse<T>>(
                  method,
                  serviceUrl,
                  withAuth,
                  1,
                  context,
                  errorCallback,
                  request
                );
              } catch (err) { }
            } else {
              if (errorCallback) {
                errorCallback!(error);
              }
            }
          } else {
            //Bağlantı hatası gibi diğer hatalar buraya düşer
            if (errorCallback) {
              errorCallback!(error);
            }
          }

          //refresh token süresi geçtiğinde başlangıç sayfasına göndermesi için eklendi. test edilmelidir throw error bu yüzden kaldırıldı
          throw error;
          //return error; 
          //return Promise.reject(error); //birkez daha buraya düşüyor
        }
      );

      switch (method) {
        case ServiceCallTypeEnum.Get:
          return await axiosApiInstance.get<T, R>(serviceUrl, requestConfig);
        case ServiceCallTypeEnum.Post:
          return await axiosApiInstance.post<T, R>(
            serviceUrl,
            request,
            requestConfig
          );
        case ServiceCallTypeEnum.Put:
          return await axiosApiInstance.put<T, R>(
            serviceUrl,
            request,
            requestConfig
          );
        case ServiceCallTypeEnum.Delete:
          return await axiosApiInstance.delete<T, R>(serviceUrl, requestConfig);
      }
    } catch (err: any) {
      throw err;
    }
  }
}
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

function parseISO(value: any): any {
  return new Date(value);
}
